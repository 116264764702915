import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import { ReactComponent as Rss } from './rss.svg'
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as Linkedin } from './linkedin.svg'
import { createRenderer } from 'fela'
import { RendererProvider } from 'react-fela'

import 'typeface-oswald'
import './layout.scss'
import './bootstrap.scss'

import { withHandlers, withState } from 'recompose'

const LiLink = ({ text, to }) => (
  <li className="nav-item">
    <Link className="nav-link" to={to}>
      {text}
    </Link>
  </li>
)

const renderer = createRenderer()

const backgroundImage = ({
  childImageSharp: {
    fluid: { src, srcSet },
  },
}) => {
  let rule = {
    backgroundImage: `url(${src})`,
  }

  const breakpoints = srcSet
    .split(',\n')
    .map((line) => line.split(' '))
    .sort(([_urla, a], [_urlb, b]) => parseInt(a) - parseInt(b))

  return breakpoints.reduce((acc, [url, width], i) => {
    const maxWidth = width.replace('w', 'px')
    let selector
    if (i === 0) {
      selector = `@media (max-width: ${width.replace('w', 'px')})`
    } else {
      const minWidth = breakpoints[i - 1][1].replace('w', 'px')
      selector = `@media (min-width: ${minWidth}) and (max-width: ${maxWidth})`
    }
    acc[selector] = { backgroundImage: `url(${url})` }
    return acc
  }, rule)
}

const NavList = ({ className }) => (
  <ul className={className}>
    <LiLink text="Home" to="/" />
    <LiLink text="Biography" to="/biography" />
    <LiLink text="Gear" to="/gear" />
    <LiLink text="Lessons" to="/lessons" />
    <LiLink text="Transcriptions" to="/transcriptions" />
    <LiLink text="Events" to="/events" />
    <LiLink text="Articles" to="/articles" />
    <LiLink text="Contact" to="/contact" />
  </ul>
)

const TopNav = ({ nav, toggleNav, title }) => (
  <nav className="navbar sticky-top navbar-light bg-light">
    <button
      className={`navbar-toggler ${nav ? '' : 'collapsed'}`}
      type="button"
      aria-expanded={nav ? 'true' : 'false'}
      aria-label="Toggle navigation"
      onClick={toggleNav}
    >
      <span className="navbar-toggler-icon" />
    </button>
    <a className="navbar-brand" href="#">
      {title}
    </a>
    <div className={`navbar-collapse collapse ${nav ? 'show' : ''}`}>
      <NavList className="navbar-nav mr-auto mt-2 mt-lg-0" />
    </div>
  </nav>
)

const SocialLi = ({ to, child }) => (
  <li>
    <a href={to} target="_blank" rel="noopener noreferrer">
      {child}
    </a>
  </li>
)

const DumbBody = ({ title, children, nav, toggleNav }) => (
  <>
    <div className="d-md-none row" style={{ marginLeft: 0, marginRight: 0 }}>
      <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <TopNav nav={nav} toggleNav={toggleNav} title={title} />
      </div>
    </div>
    <div className="container" id="main-container">
      <div className="row">
        <div className="d-none d-md-block col-md-3 col-lg-4" id="left_side">
          <div className="left_side_content">
            <div className="logo">
              <Link to="/">
                <h1>Jeffery Utter</h1>
                <h3 className="subheader">Bassist, Educator</h3>
              </Link>
            </div>
            <NavList className="side-nav" />
          </div>
          <div className="left_side_bottom">
            <h3>Social Media</h3>
            <ul className="social_icon">
              <SocialLi to="/blog/feed.rss" child={<Rss />} />
              <SocialLi to="http://twitter.com/jeffutter" child={<Twitter />} />
              <SocialLi
                to="http://www.linkedin.com/in/jeffutter/"
                child={<Linkedin />}
              />
            </ul>
            <p>
              Copyright © {new Date().getYear() + 1900}. All Rights Reserved
            </p>
          </div>
        </div>
        <div className="col-12 col-md-9 col-lg-8" id="content">
          {children}
        </div>
      </div>
    </div>
  </>
)

const Body = withState(
  'nav',
  'navOpen',
  false
)(
  withHandlers({
    toggleNav:
      ({ navOpen }) =>
      () =>
        navOpen((n) => !n),
  })(DumbBody)
)

const Layout = ({ children }) => (
  <RendererProvider renderer={renderer}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site: site {
            siteMetadata {
              title
            }
          }
          background: file(relativePath: { eq: "body-pattern.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={({ site, background }) => {
        const bodyClass = renderer.renderRule(backgroundImage, background)
        const bodyAttributes = {}
        bodyAttributes['class'] = bodyClass
        return (
          <>
            <Helmet
              title={site.siteMetadata.title}
              meta={[]}
              bodyAttributes={bodyAttributes}
            >
              <meta charSet="utf-8" />
              <meta httpEquiv="x-ua-compatible" content="ie=edge" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
              />
              <html lang="en" />
            </Helmet>
            <Body title={site.siteMetadata.title}>{children}</Body>
          </>
        )
      }}
    />
  </RendererProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
